// Common or dashboard
export const SET_IS_LOADING = "SET_IS_LOADING";
export const UNSET_IS_LOADING = "UNSET_IS_LOADING";
export const SET_IS_SIDEBAR_OPEN = "SET_IS_SIDEBAR_OPEN";
// Admin Login
export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

export const ADMIN_LOGOUT_REQUEST = 'ADMIN_LOGOUT_REQUEST';
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCESS';
export const ADMIN_LOGOUT_FAILURE = 'ADMIN_LOGOUT_FAILURE';

export const ADMIN_CHANGE_PASSWORD_REQUEST = 'ADMIN_CHANGE_PASSWORD_REQUEST';
export const ADMIN_CHANGE_PASSWORD_SUCCESS = 'ADMIN_CHANGE_PASSWORD_SUCCESS';
export const ADMIN_CHANGE_PASSWORD_FAILURE = 'ADMIN_CHANGE_PASSWORD_FAILURE';


//Review
export const GET_ASTROLOGERS_REVIEWS = "GET_ASTROLOGERS_REVIEWS";
export const SET_ASTROLOGERS_REVIEWS = "SET_ASTROLOGERS_REVIEWS";
export const GET_APP_REVIEWS = "GET_APP_REVIEWS";
export const SET_APP_REVIEWS = "SET_APP_REVIEWS";
export const UPDATE_ASTROLOER_REVIEW_STATUS = "UPDATE_ASTROLOER_REVIEW_STATUS";
export const DELETE_ASTROLOGER_REVIEW = "DELETE_ASTROLOGER_REVIEW";

//Customer
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
export const SET_ALL_CUSTOMER = "SET_ALL_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const BAN_CUSTOMER = "BAN_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";

//Astrologer
export const SET_ASTROLOGER = "SET_ASTROLOGER";
export const GET_ALL_ASTROLOGER = "GET_ALL_ASTROLOGER";
export const GET_ALL_ACTIVE_ASTROLOGER = "GET_ALL_ACTIVE_ASTROLOGER";
export const GET_ASTROLOGER = "GET_ASTROLOGER";
export const SET_ALL_ASTROLOGER = "SET_ALL_ASTROLOGER";
export const UPDATE_ASTROLOGER_CHAT_STATUS = "UPDATE_ASTROLOGER_CHAT_STATUS";
export const UPDATE_ASTROLOER_CALL_STATUS = "UPDATE_ASTROLOER_CALL_STATUS";
export const GET_ENQUIRY_ASTROLOGERS = "GET_ENQUIRY_ASTROLOGERS";
export const SET_ENQUIRY_ASTROLOGERS = "SET_ENQUIRY_ASTROLOGERS";
export const UPDATE_ENQUIRY_STATUS = "UPDATE_ENQUIRY_STATUS";
export const UPDATE_ASTROLOGER_DATA = "UPDATE_ASTROLOGER_DATA";
export const UPDATE_ASTROLOGER_STATUS = "UPDATE_ASTROLOGER_STATUS";
// export const UPDATE_ASTROLOGER_CALL_STATUS = "UPDATE_ASTROLOGER_CALL_STATUS";
export const ADD_ASTROLOGER = "ADD_ASTROLOGER";
export const VERIFY_UNVERIFY_ASTROLOGER = "VERIFY_UNVERIFY_ASTROLOGER";
export const DELETE_ASTROLOGER = "DELETE_ASTROLOGER";

export const UPDATE_ASTROLOGER_SKILL = "UPDATE_ASTROLOGER_SKILL";
export const UPDATE_ASTROLOGER_REMEDIES = "UPDATE_ASTROLOGER_REMEDIES";
export const UPDATE_ASTROLOGER_EXPERTIES = "UPDATE_ASTROLOGER_EXPERTIES";
export const UPDATE_ASTROLOGER_ALLOWED_COUNTRIES = "UPDATE_ASTROLOGER_ALLOWED_COUNTRIES";
export const UPDATE_ASTROLOGER_PREFERRED_DAYS = "UPDATE_ASTROLOGER_PREFERRED_DAYS";
export const UPDATE_ASTROLOGER_PROFILE_IMAGE = "UPDATE_ASTROLOGER_PROFILE_IMAGE";
export const UPDATE_ASTROLOGER_BANK_PROOF_IMAGE = "UPDATE_ASTROLOGER_BANK_PROOF_IMAGE";
export const UPDATE_ASTROLOGER_ID_PROOF_IMAGE = "UPDATE_ASTROLOGER_ID_PROOF_IMAGE";
export const UPDATE_ASTROLOGER_GALLERY_IMAGE = "UPDATE_ASTROLOGER_GALLERY_IMAGE";


//Experties
export const GET_ALL_EXPERTIES = "GET_ALL_EXPERTIES";
export const GET_ALL_ACTIVE_EXPERTIES = "GET_ALL_ACTIVE_EXPERTIES";
export const GET_ALL_MAIN_EXPERTIES = "GET_ALL_MAIN_EXPERTIES";
export const SET_ALL_EXPERTIES = "SET_ALL_EXPERTIES";
export const SET_ALL_MAIN_EXPERTIES = "SET_ALL_MAIN_EXPERTIES";
export const CREATE_EXPERTIES = "CREATE_EXPERTIES";
export const CREATE_MAIN_EXPERTIES = "CREATE_MAIN_EXPERTIES";
export const UPDATE_EXPERTIES = "UPDATE_EXPERTIES";
export const UPDATE_MAIN_EXPERTIES = "UPDATE_MAIN_EXPERTIES";
export const DELETE_EXPERTIES = "DELETE_EXPERTIES";
export const DELETE_MAIN_EXPERTIES = "DELETE_MAIN_EXPERTIES";
export const UPDATE_EXPERTISE_STATUS = "UPDATE_EXPERTISE_STATUS";


//Skills
export const CREATE_SKILL = "CREATE_SKILL";
export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const GET_ALL_ACTIVE_SKILLS = "GET_ALL_ACTIVE_SKILLS";
export const SET_ALL_SKILLS = "SET_ALL_SKILLS";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";
export const UPDATE_SKILL_STATUS = "UPDATE_SKILL_STATUS";

export const CREATE_SUB_SKILL = "CREATE_SUB_SKILL";
export const GET_ALL_SUB_SKILLS = "GET_ALL_SUB_SKILLS";
export const SET_ALL_SUB_SKILLS = "SET_ALL_SUB_SKILLS";
export const UPDATE_SUB_SKILL = "UPDATE_SUB_SKILL";
export const DELETE_SUB_SKILL = "DELETE_SUB_SKILL";
export const UPDATE_SUB_SKILL_STATUS = "UPDATE_SUB_SKILL_STATUS";

//Remedies
export const CREATE_REMEDY = "CREATE_REMEDY";
export const GET_ALL_REMEDIES = "GET_ALL_REMEDIES";
export const GET_ALL_ACTIVE_REMEDIES = "GET_ALL_ACTIVE_REMEDIES";
export const SET_ALL_REMEDIES = "SET_ALL_REMEDIES";
export const UPDATE_REMEDY = "UPDATE_REMEDY";
export const DELETE_REMEDY = "DELETE_REMEDY";
export const CHANGE_REMEDY_STATUS = "CHANGE_REMEDY_STATUS"

//Redirection Banners
export const CREATE_REDIRECTION_BANNER = 'CREATE_REDIRECTION_BANNER'
export const GET_REDIRECTION_BANNER = 'GET_REDIRECTION_BANNER'
export const SET_REDIRECTION_BANNER = 'SET_REDIRECTION_BANNER'
export const UPDATE_REDIRECTION_BANNER = 'UPDATE_REDIRECTION_BANNER'
export const DELETE_REDIRECTION_BANNER = 'DELETE_REDIRECTION_BANNER'
export const CHANGE_REDIRECTION_BANNER_STATUS = 'CHANGE_REDIRECTION_BANNER_STATUS'


//Course Banner
export const GET_ALL_COURSE_BANNER = 'GET_ALL_COURSE_BANNER'
export const CREATE_COURSE_BANNER = 'CREATE_COURSE_BANNER'
export const UPDATE_COURSE_BANNER = 'UPDATE_COURSE_BANNER'
export const UPDATE_COURSE_BANNER_STATUS = 'UPDATE_COURSE_BANNER_STATUS'
export const DELETE_COURSE_BANNER = 'DELETE_COURSE_BANNER'

//Ecommerce Banner
export const GET_ALL_ECOMMERCE_BANNER = 'GET_ALL_ECOMMERCE_BANNER'
export const CREATE_ECOMMERCE_BANNER = 'CREATE_ECOMMERCE_BANNER'
export const UPDATE_ECOMMERCE_BANNER = 'UPDATE_ECOMMERCE_BANNER'
export const UPDATE_ECOMMERCE_BANNER_STATUS = 'UPDATE_ECOMMERCE_BANNER_STATUS'
export const DELETE_ECOMMERCE_BANNER = 'DELETE_ECOMMERCE_BANNER'

//Astrologer Banner
export const GET_ALL_ASTROLOGER_BANNER = 'GET_ALL_ASTROLOGER_BANNER'
export const CREATE_ASTROLOGER_BANNER = 'CREATE_ASTROLOGER_BANNER'
export const UPDATE_ASTROLOGER_BANNER = 'UPDATE_ASTROLOGER_BANNER'
export const UPDATE_ASTROLOGER_BANNER_STATUS = 'UPDATE_ASTROLOGER_BANNER_STATUS'
export const DELETE_ASTROLOGER_BANNER = 'DELETE_ASTROLOGER_BANNER'

//Product Banner
export const GET_ALL_PRODUCT_BANNER = 'GET_ALL_PRODUCT_BANNER'
export const CREATE_PRODUCT_BANNER = 'CREATE_PRODUCT_BANNER'
export const UPDATE_PRODUCT_BANNER = 'UPDATE_PRODUCT_BANNER'
export const UPDATE_PRODUCT_BANNER_STATUS = 'UPDATE_PRODUCT_BANNER_STATUS'
export const DELETE_PRODUCT_BANNER = 'DELETE_PRODUCT_BANNER'

//Pooja Banner
export const GET_ALL_POOJA_BANNER = 'GET_ALL_POOJA_BANNER'
export const CREATE_POOJA_BANNER = 'CREATE_POOJA_BANNER'
export const UPDATE_POOJA_BANNER = 'UPDATE_POOJA_BANNER'
export const UPDATE_POOJA_BANNER_STATUS = 'UPDATE_POOJA_BANNER_STATUS'
export const DELETE_POOJA_BANNER = 'DELETE_POOJA_BANNER'

//Call-Chat Banner
export const GET_ALL_CALL_CHAT_BANNER = 'GET_ALL_CALL_CHAT_BANNER'
export const CREATE_CALL_CHAT_BANNER = 'CREATE_CALL_CHAT_BANNER'
export const UPDATE_CALL_CHAT_BANNER = 'UPDATE_CALL_CHAT_BANNER'
export const UPDATE_CALL_CHAT_BANNER_STATUS = 'UPDATE_CALL_CHAT_BANNER_STATUS'
export const DELETE_CALL_CHAT_BANNER = 'DELETE_CALL_CHAT_BANNER'


//Notification
export const GET_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const GET_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";
export const SET_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const SET_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";
export const SEND_CUSTOMER_NOTIFICATIONS = "SEND_CUSTOMER_NOTIFICATIONS";
export const SEND_ASTROLOGER_NOTIFICATIONS = "SEND_ASTROLOGER_NOTIFICATIONS";
export const DELETE_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const DELETE_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";

export const UPDATE_CUSTOMER_NOTIFICATION_STATUS = 'UPDATE_CUSTOMER_NOTIFICATION_STATUS'
export const UPDATE_ASTROLOGER_NOTIFICATION_STATUS = 'UPDATE_ASTROLOGER_NOTIFICATION_STATUS'
export const UPDATE_CUSTOMER_NOTIFICATION = 'UPDATE_CUSTOMER_NOTIFICATION'
export const UPDATE_ASTROLOGER_NOTIFICATION = 'UPDATE_ASTROLOGER_NOTIFICATION'
export const DELETE_CUSTOMER_NOTIFICATION = 'DELETE_CUSTOMER_NOTIFICATION'
export const DELETE_ASTROLOGER_NOTIFICATION = 'DELETE_ASTROLOGER_NOTIFICATION'
export const ADD_CUSTOMER_NOTIFICATION = 'ADD_CUSTOMER_NOTIFICATION'
export const ADD_ASTROLOGER_NOTIFICATION = 'ADD_ASTROLOGER_NOTIFICATION'



//History
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const GET_CHAT_SUMMARY = "GET_CHAT_SUMMARY";
export const SET_CHAT_SUMMARY = "SET_CHAT_SUMMARY";
export const GET_CALL_HISTORY = "GET_CALL_HISTORY";
export const SET_CALL_HISTORY = "SET_CALL_HISTORY";
export const SET_CUSTOMER_FIREBASE_ID = 'SET_CUSTOMER_FIREBASE_ID'

//Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";

//Reports
export const GET_ADMIN_EARNINGS = "GET_ADMIN_EARNINGS";
export const SET_ADMIN_EARNINGS = "SET_ADMIN_EARNINGS";
export const GET_RECIEPT_SUMMARY = 'GET_RECIEPT_SUMMARY'
export const SET_RECIEPT_SUMMARY = 'SET_RECIEPT_SUMMARY'

//Language
export const CREATE_LANGUAGE = "CREATE_LANGUAGE";
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const SET_ALL_LANGUAGE = "SET_ALL_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

//Country
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const GET_COUNTRY_VALUE = "GET_COUNTRY_VALUE";
export const SET_ALL_COUNTRY = "SET_ALL_COUNTRY";
export const UPDATE_COUNTRY_STATUS = "UPDATE_COUNTRY_STATUS";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY" ;
export const COUNTRY_STATE_LIST = "COUNTRY_STATE_LIST";

//State
export const GET_ALL_STATE = "GET_ALL_STATE";
export const UPDATE_STATE_STATUS = "UPDATE_STATE_STATUS";
export const UPDATE_STATE = "UPDATE_STATE";
export const CREATE_STATE = "CREATE_STATE";
export const DELETE_STATE = "DELETE_STATE" 

//City
export const GET_ALL_CITY = "GET_ALL_CITY";
export const UPDATE_CITY_STATUS = "UPDATE_CITY_STATUS";
export const UPDATE_CITY = "UPDATE_CITY";
export const CREATE_CITY = "CREATE_CITY";
export const DELETE_CITY = "DELETE_CITY" 
export const STATE_CITY_LIST = "STATE_CITY_LIST";


//Recharge Plan

export const CREATE_RECHARGE_PLAN = 'CREATE_RECHARGE_PLAN';
export const GET_RECHARGE_PLAN = 'GET_RECHARGE_PLAN'
export const SET_RECHARGE_PLAN = 'SET_RECHARGE_PLAN'
export const UPDATE_RECHARGE_PLAN = 'UPDATE_RECHARGE_PLAN'
export const DELETE_RECHARGE_PLAN = 'DELETE_RECHARGE_PLAN'
export const UPDATE_RECHARGE_PLAN_STATUS = 'UPDATE_RECHARGE_PLAN_STATUS'

//FirstRecharge Offer
export const CREATE_FIRST_RECHARGE_OFFER = 'CREATE_FIRST_RECHARGE_OFFER'
export const GET_FIRST_RECHARGE_OFFER = 'GET_FIRST_RECHARGE_OFFER'
export const SET_FIRST_RECHARGE_OFFER = 'SET_FIRST_RECHARGE_OFFER'
export const UPDATE_FIRST_RECHARGE_OFFER = 'UPDATE_FIRST_RECHARGE_OFFER'
export const UPDATE_FIRST_RECHARGE_OFFER_STATUS = 'UPDATE_FIRST_RECHARGE_OFFER_STATUS'
export const DELETE_FIRST_RECHARGE_OFFER = 'DELETE_FIRST_RECHARGE_OFFER'

//Product Category
export const CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY'
export const PRODUCT_CATEGORY_LIST = 'PRODUCT_CATEGORY_LIST'
export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY'
export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY'
export const UPDATE_PRODUCT_CATEGORY_STATUS = 'UPDATE_PRODUCT_CATEGORY_STATUS'

//Product 
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const PRODUCT_LIST = 'PRODUCT_LIST'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS'

//Pooja Category
export const CREATE_POOJA_CATEGORY = 'CREATE_POOJA_CATEGORY'
export const POOJA_CATEGORY_LIST = 'POOJA_CATEGORY_LIST'
export const UPDATE_POOJA_CATEGORY = 'UPDATE_POOJA_CATEGORY'
export const DELETE_POOJA_CATEGORY = 'DELETE_POOJA_CATEGORY'
export const UPDATE_POOJA_CATEGORY_STATUS = 'UPDATE_POOJA_CATEGORY_STATUS'
export const UPDATE_POOJA_CATEGORY_IMAGE = 'UPDATE_POOJA_CATEGORY_IMAGE'
export const CREATE_POOJA_CATEGORY_SUCCESS = 'CREATE_POOJA_CATEGORY_SUCCESS';
export const CREATE_POOJA_CATEGORY_FAILURE = 'CREATE_POOJA_CATEGORY_FAILURE';
export const POOJA_CATEGORY_LIST_SUCCESS= 'POOJA_CATEGORY_LIST_SUCCESS'
export const POOJA_CATEGORY_LIST_FAILURE = 'POOJA_CATEGORY_LIST_FAILURE'

//Pooja
export const CREATE_POOJA = 'CREATE_POOJA'
export const POOJA_LIST = 'POOJA_LIST'
export const UPDATE_POOJA = 'UPDATE_POOJA'
export const DELETE_POOJA = 'DELETE_POOJA'
export const UPDATE_POOJA_STATUS = 'UPDATE_POOJA_STATUS'

//BLOG  CATEGORY
export const CREATE_BLOG_CATEGORY='CREATE_BLOG_CATEGORY'
export const BLOG_CATEGORY_LIST='BLOG_CATEGORY_LIST'
export const UPDATE_BLOG_CATEGORY='UPDATE_BLOG_CATEGORY'
export const DELETE_BLOG_CATEGORY='DELETE_BLOG_CATEGORY'
export const ACTIVE_BLOG_CATEGORY='ACTIVE_BLOG_CATEGORY'


//BLOG
export const CREATE_BLOG='CREATE_BLOG'
export const BLOG_LIST='BLOG_LIST'
export const UPDATE_BLOG='UPDATE_BLOG'
export const DELETE_BLOG='DELETE_BLOG'
export const DELETE_MULTIPLE_BLOG='DELETE_MULTIPLE_BLOG'
export const UPDATE_BLOG_STATUS='UPDATE_BLOG_STATUS'

//TESTIMONIAL
export const CREATE_TESTIMONIAL='CREATE_TESTIMONIAL'
export const TESTIMONIAL_LIST='TESTIMONIAL_LIST'
export const UPDATE_TESTIMONIAL='UPDATE_TESTIMONIAL'
export const DELETE_TESTIMONIAL='DELETE_TESTIMONIAL'
export const UPDATE_TESTIMONIAL_STATUS = 'UPDATE_TESTIMONIAL_STATUS'

//COURSES
export const CREATE_COURSE='CREATE_COURSE'
export const GET_ALL_COURSES='GET_ALL_COURSES'
export const GET_ACTIVE_COURSES='GET_ACTIVE_COURSES'
export const UPDATE_COURSE='UPDATE_COURSE'
export const DELETE_COURSE='DELETE_COURSE'
export const UPDATE_COURSE_STATUS = 'UPDATE_COURSE_STATUS'

//DEMO CLASS
export const CREATE_DEMO_CLASS='CREATE_DEMO_CLASS'
export const DEMO_CLASS_LIST='DEMO_CLASS_LIST'
export const UPDATE_DEMO_CLASS='UPDATE_DEMO_CLASS'
export const DELETE_DEMO_CLASS='DELETE_DEMO_CLASS'
export const UPDATE_DEMO_CLASS_STATUS = 'UPDATE_DEMO_CLASS_STATUS'
export const UPDATE_DEMO_CLASS_ADMIN_STATUS = 'UPDATE_DEMO_CLASS_ADMIN_STATUS'

//LIVE CLASS
export const CREATE_LIVE_CLASS='CREATE_LIVE_CLASS'
export const LIVE_CLASS_LIST='LIVE_CLASS_LIST'
export const UPDATE_LIVE_CLASS='UPDATE_LIVE_CLASS'
export const DELETE_LIVE_CLASS='DELETE_LIVE_CLASS'
export const UPDATE_LIVE_CLASS_STATUS = 'UPDATE_LIVE_CLASS_STATUS'
export const UPDATE_LIVE_CLASS_ADMIN_STATUS = 'UPDATE_LIVE_CLASS_ADMIN_STATUS'

//SCHEDULE LIVE CLASS
export const SCHEDULE_CLASS='SCHEDULE_CLASS'
export const SCHEDULE_CLASS_LIST='SCHEDULE_CLASS_LIST'
export const UPDATE_SCHEDULE_CLASS='UPDATE_SCHEDULE_CLASS'
export const DELETE_SCHEDULE_CLASS='DELETE_SCHEDULE_CLASS'
export const UPDATE_SCHEDULE_CLASS_STATUS = 'UPDATE_SCHEDULE_CLASS_STATUS'

//WORKSHOP
export const CREATE_WORKSHOP='CREATE_WORKSHOP'
export const WORKSHOP_LIST='WORKSHOP_LIST'
export const UPDATE_WORKSHOP='UPDATE_WORKSHOP'
export const DELETE_WORKSHOP='DELETE_WORKSHOP'
export const UPDATE_WORKSHOP_STATUS = 'UPDATE_WORKSHOP_STATUS'
export const UPDATE_WORKSHOP_ADMIN_STATUS = 'UPDATE_WORKSHOP_ADMIN_STATUS'

//MCQ
export const CREATE_MCQ='CREATE_MCQ'
export const MCQ_LIST='MCQ_LIST'
export const UPDATE_MCQ='UPDATE_MCQ'
export const DELETE_MCQ='DELETE_MCQ'
export const UPDATE_MCQ_STATUS = 'UPDATE_MCQ_STATUS'

//GIFT
export const CREATE_GIFT='CREATE_GIFT'
export const GIFT_LIST='GIFT_LIST'
export const UPDATE_GIFT='UPDATE_GIFT'
export const DELETE_GIFT='DELETE_GIFT'
export const UPDATE_GIFT_STATUS = 'UPDATE_GIFT_STATUS'

//PRIVACY POLICY
export const CREATE_PRIVACY_POLICY='CREATE_PRIVACY_POLICY'
export const PRIVACY_POLICY_LIST='PRIVACY_POLICY_LIST'
export const UPDATE_PRIVACY_POLICY='UPDATE_PRIVACY_POLICY'
export const DELETE_PRIVACY_POLICY='DELETE_PRIVACY_POLICY'
export const UPDATE_PRIVACY_POLICY_STATUS = 'UPDATE_PRIVACY_POLICY_STATUS'

//TERMS AND CONDITION
export const CREATE_TERMS_AND_CONDITION='CREATE_TERMS_AND_CONDITION'
export const TERMS_AND_CONDITION_LIST='TERMS_AND_CONDITION_LIST'
export const UPDATE_TERMS_AND_CONDITION='UPDATE_TERMS_AND_CONDITION'
export const DELETE_TERMS_AND_CONDITION='DELETE_TERMS_AND_CONDITION'
export const UPDATE_TERMS_AND_CONDITION_STATUS = 'UPDATE_TERMS_AND_CONDITION_STATUS'

//REQUEST
export const GET_ALL_PROFILE_REQUEST = 'GET_ALL_PROFILE_REQUEST'
export const VERIFY_PROFILE_REQUEST = 'VERIFY_PROFILE_REQUEST'
export const GET_ALL_PHONE_NUMBER_REQUEST = 'GET_ALL_PHONE_NUMBER_REQUEST'
export const VERIFY_PHONE_NUMBER_REQUEST = 'VERIFY_PHONE_NUMBER_REQUEST'
export const GET_ALL_BANK_REQUEST = 'GET_ALL_BANK_REQUEST'
export const VERIFY_BANK_REQUEST = 'VERIFY_BANK_REQUEST'
export const GET_ALL_GALLERY_IMAGE_REQUEST = 'GET_ALL_GALLERY_IMAGE_REQUEST'
export const VERIFY_GALLERY_IMAGE_REQUEST = 'VERIFY_GALLERY_IMAGE_REQUEST'








